import React, { useEffect } from 'react';
import history from '../../goodBeefIndex/gbiHistory';
import { gbiElements } from '../../admin/scoreDefinitions/util';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNextIncompleteScoreAttribute, tagComplete } from '../reducer';
import PropTypes from 'prop-types';
import './intro.scss';
import ShortArrow from '../../util/icons/components/ShortArrow';
import { Helmet } from 'react-helmet';

const Intro = ({ match }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, []);
  const nextIncompleteScore = useSelector(getNextIncompleteScoreAttribute);
  const complete = useSelector(tagComplete);
  const menuItems = gbiElements.map((element, index) => {
    const handleClick = () => {
      history.push(`${this.props.match.url}/${element}`);
    };
    return (
      <li className='element-menu-item'
        onClick={ handleClick }
        data-cy={ `element-menu-item-${index}` }
        key={ element }>
        <div className="inner"></div>
      </li>
    );
  });
  const visibilityClass = complete ? null : 'hidden';
  const progressMenu = (
    <ul cy="progress-menu" className={ 'progress-menu ' + visibilityClass }>
      <li data-cy="element-menu-item-0"
        className='progress-menu-item active'>
        <div className="inner"></div>
      </li>
      { menuItems }
    </ul>
  );
  const url = `${match.url}/${nextIncompleteScore}`;
  const meta = 'GoodBeef Index measures and grades the quality your beef. ' +
    'Rate your animal against the three key elements of beef quality - ' +
    'Production, Eating and Environment.';
  return (
    <div id="try-it-out-intro">
      <Helmet>
        <title>
          Grade your animal on the GoodBeef Index
        </title>
        <meta name="description" content={ meta } />
      </Helmet>
      <div className="top">
        <h5 className="try-it-out">try it out</h5>
        <h1 className="title">Enter Animal Details</h1>
      </div>
      <div data-cy="next-section-block"
        className='next-section'>
        <Link to={ url } className="next-section-link">
          <span data-cy="next-section-link">
            <ShortArrow />
              Begin
          </span>
        </Link>
      </div>
      { progressMenu }
    </div>
  );
};

Intro.propTypes = {
  complete: PropTypes.bool,
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

export default Intro;
